<template>
  <div class="hello">
    <img  :src="LogoFilePath"  class="logo2" />
    <div class="buttondiv">
      <button class="demobutton" v-if="timeclockURL!=''">
          <a  :href="getUrl(timeclockURL)"  tabindex="-1" class="demobutton" target="_blank">
              <div class="horizFlex">
                <img src="@/assets/clock.svg" class="buttonicon" />
                <label class="buttontext">Instructor Time</label>
              </div>
          </a>
      </button>
      <button class="demobutton"  v-if="showTimeAdmin">
          <a  :href="getUrl(timeAdminURL)"  tabindex="-1" class="demobutton" target="_blank">
              <div class="horizFlex">
                <img src="@/assets/time-fast.svg" class="buttonicon" />
                <label class="buttontext">Time Admin</label>
              </div>
          </a>
      </button>
      <button class="demobutton" v-if="showGrade">
          <a  :href="getUrl(gradingURL)"  tabindex="-1" class="demobutton" target="_blank">
              <div class="horizFlex">
                <img src="@/assets/grade.svg" class="buttonicon" />
                <label class="buttontext">Grading</label>
              </div>
          </a>
      </button>
    </div>
    <div class="buttondiv">
 
       <button class="demobutton" v-if="portalURL!=''">
          <a  :href="getUrl(portalURL)"  tabindex="-1" class="demobutton" target="_blank">
              <div class="horizFlex">
                <img src="@/assets/edit.svg" class="buttonicon" />
                <label class="buttontext">Sign Up</label>
              </div>
          </a>
      </button>
      <button class="demobutton" v-if="adminURL!=''">
          <a  :href="getUrl(adminURL)"  tabindex="-1" class="demobutton" target="_blank">
              <div class="horizFlex">
                <img src="@/assets/settings.svg" class="buttonicon" />
                <label class="buttontext">Student Admin</label>
              </div>
          </a>
      </button>
      <button class="demobutton" v-if="mobileidURL!=''">
          <a  :href="getUrl(mobileidURL)"  tabindex="-1" class="demobutton" target="_blank">
              <div class="horizFlex">
                <img src="@/assets/id-card.png" class="buttonicon" />
                <label class="buttontext">Mobile ID</label>
              </div>
          </a>
      </button>
      <button class="demobutton"  v-if="mobileidadminURL!=''">
          <a  :href="getUrl(mobileidadminURL)"  tabindex="-1" class="demobutton" target="_blank">
              <div class="horizFlex">
                <img src="@/assets/settings.svg" class="buttonicon" />
                <label class="buttontext">Badge Admin</label>
              </div>
          </a>
      </button>
      <br>
      <br>
      <a :href="FMCSAURL" target="_blank" v-if="FMCSAURL!=''">FMCSA Training Provider Registry</a>
      <br>
      <br>
      <a :href="CVTAURL" target="_blank" v-if="CVTAURL!=''">CVTA.org</a>

      

      
    </div>
     

  </div>
</template>

<script>
import datamethods from '../datamethods'
export default {
  name: 'HelloWorld',
  mixins:[datamethods],

  data(){
    return{
        timeclockURL:window.timeclockURL,
        adminURL:window.adminURL,
        portalURL:window.portalURL,
        mobileidURL:window.mobileidURL,
        mobileidadminURL:window.mobileidadminURL,
        FMCSAURL:window.FMCSAURL,
        CVTAURL:window.CVTAURL,

        imageBasePath:'',
        logoOverride:'',
        configOverride:window.CONFIG_OVERRIDE,


    }
  },
  computed:{
    showGrade(){
      return window.gradingURL!=''
    },
    showTimeAdmin(){
      return window.timeAdminURL!=''
    },
    gradingURL(){
      return window.gradingURL + this.configOverride
    },
    timeAdminURL(){
      return window.timeAdminURL + this.configOverride
    },
    LogoFilePath(){
        if(this.logoOverride!=''){
            return  this.imageBasePath + this.logoOverride
        }else if(window.LOGO_OVERRIDE!=undefined){
          if(window.LOGO_OVERRIDE!=''){
            return './../' + window.LOGO_OVERRIDE
          }else{
            return require('@/assets/trucktrack.jpg')
          }
        
        }else{
            return require('@/assets/trucktrack.jpg')
        }

    }
  },
  mounted(){
        
        let urlParams = new URLSearchParams(window.location.search)
     
         let tmp = urlParams.get('logo')
        if(tmp!=null){ this.logoOverride = tmp;}

        let tmp2 = urlParams.get('config')
        if(tmp2!=null){ this.configOverride = tmp2;}

        this.getImageBasePath();
    },
    methods:{
      getUrl(url){
            var outurl = url + "?logo=" + this.logoOverride;
            console.log(outurl)
            return outurl
      },
      async getImageBasePath(){
          //call api
          var response = await this.callAPI(null,'imagepath')
          //handle response
          if(response.Successful){
              this.imageBasePath =  response.StringResponse
          }else{
              this.message = response.Message
          }
      },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.buttondiv{
  margin-top:40px;
}
.demobutton{
    color:white;
    background-color:#3E546C;
    border: none;
    border-radius:10px;
    width:230px;
    height:75px;
    padding:0px;
    font-size: medium;
    margin: 0px 10px 0px 10px;
  text-decoration: none;
  white-space: pre;
  margin-right:10px;
}
.horizFlex{
    display:flex;
    flex-direction: row;

  }
  .buttonicon{
    width:45px;
    margin-left:10px;
  }
   .buttontext{
     margin:7px;
     font-size:x-large;
   }
   .logo2{
     max-height: 100px;
   }
</style>
